// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query DossierTypeTagsByDossierId(
    $dossierId: ID!,
    $dossierTypeSlug: String!,
    $navigationSlug: String!,
  ) {
    autocompleteTags: dossierTypeTagsByDossierId(
      id: $dossierId,
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug,
    ) {
      id
      label
      label_color
      label_text_color
    }
  }
`;

export default QUERY;
